
a:link {
    color: #88b9e4;
    background-color: transparent;
    text-decoration: none;
}

a:visited {
    color: #88b9e4;
    background-color: transparent;
    text-decoration: none;
}

a:hover {
    color: rgba(153, 198, 97,0.5);
    background-color: transparent;
    text-decoration: underline;
}

a:active {
    color: #E3FDEE;
    background-color: transparent;
    text-decoration: underline;
}
