.App {
    text-align: center;
}

.App-logo2 {
    height: 40%;
    width:40%;
    pointer-events: none;
}
.logo2{
    width:40%;
}
@media (prefers-reduced-motion: no-preference) {
    .App-logo2 {
        animation: App-logo2-spin infinite 30s linear;

    }
}


@keyframes App-logo2-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.normaltext{
    font-size: 40vw;
}

