.MuiTab-wrapper {


}

.MuiTabs-fixed{
    height: 50px;
}

.xyz{
    align-items: flex-end;
}
