.App {
    text-align: center;
}

.App-logo {
    height: 100pt;
    pointer-events: none;
}
.App-logo {
    height:30%;
    width:30%;
}
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 100s linear;
    }
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
 .nf{
     height:100%;
     width:100%;
 }